/**
 * @flow
 */
import React from 'react';
import PageContainer from './PageContainer';
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import {DefaultButton} from '../../components/buttons';
import {serviceTypeOptions} from '../../forms/CustomerInputForm';
import {util} from '../../services/service';
import useConsentModal from '../../modals/useConsentModal';
import {useLocation} from 'react-router-dom';

const buttonStyle = {
  width: '100%',
  height: '96px',
  fontSize: 20,
  whiteSpace: 'pre-line',
  // fontWeight: 'bold',
};

export const basePathRegex = /\/tablet\/[\d]+/;
export const dropzoneIdRegex = /\/tablet\/([\d]+)/;

const ServiceSelector = () => {
  const location = useLocation();
  const basePath = location.pathname.match(basePathRegex)?.[0] ?? '/';
  const dropzoneId = basePath.match(dropzoneIdRegex)?.[1];
  const serviceRef = React.useRef();
  const destinationRef = React.useRef();
  const modal = useConsentModal('I AGREE', () => {
    util.nav(`${basePath}/form?s=${serviceRef.current}&d=${destinationRef.current}`);
    goFullScreen();
  });
  const onClick = ({value}, destination = '') => {
    serviceRef.current = value;
    destinationRef.current = destination;
    modal.open(value);
  };
  const goFullScreen = () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    }
  };
  return (
    <PageContainer pageContentStyle={{display: 'flex', alignItems: 'center'}}>
      <Container fluid={true}>
        <div onLoad={goFullScreen()}>
        <Card>
          <CardBody>
            <h3 className={'mb-4'}>SELECT SERVICE</h3>
            <Row>
              <Col className={'mb-4'} sm={6}><Selector option={serviceTypeOptions[1]} onClick={onClick} destination={'KOREA'} /></Col>
              <Col className={'mb-4'} sm={6}><Selector option={serviceTypeOptions[2]} onClick={onClick} destination={'KOREA'} /></Col>
            </Row>
            <Row>
              <Col className={'mb-4'} sm={6}><Selector option={serviceTypeOptions[1]} onClick={onClick} destination={'CANADA'} /></Col>
              <Col className={'mb-4'} sm={6}><Selector option={serviceTypeOptions[2]} onClick={onClick} destination={'CANADA'} /></Col>
            </Row>
            <Row>
              <Col className={'mb-4'} sm={6}><Selector option={serviceTypeOptions[3]} onClick={onClick} /></Col>
              <Col className={'mb-4'} sm={6}><Selector option={serviceTypeOptions[4]} onClick={onClick} /></Col>
            </Row>
          </CardBody>
        </Card>
        </div>
        {modal.render()}
      </Container>
    </PageContainer>
  );
};

const Selector = ({option, onClick, destination}) => {
  let label = '';
  if (destination === 'KOREA') {
    label = '(캐나다 ⟶ 한국)';
  } else if (destination === 'CANADA') {
    label = '(한국 ⟶ 캐나다)';
  }
  return <DefaultButton label={option.label + label} onClick={() => onClick(option, destination)} noMargin style={buttonStyle} />;
};

export default ServiceSelector;

/**
 * @flow
 */
import React from 'react';
import {useHistory} from 'react-router-dom';
import {Container, Row, Col, Card, CardBody} from 'reactstrap';
import FormManager from '../lib/FormManager';
import {useSimpleGrid} from '../components/SimpleGrid';
import {useSimpleEditModal} from '../components/SimpleModal';
import ItemInputForm from './ItemInputForm';
import {DefaultButton, PrimaryButton} from '../components/buttons';
import {api, util} from '../services/service';
import useConsentModal from '../modals/useConsentModal';
import * as yup from 'yup';
import {useUser} from '../redux/reducers/userReducer';

const emailSchema = yup.string().email();

class CustomerInputForm extends FormManager {
  onSave;
  forCustomer;
  grid;
  dropzoneId;
  gridData;
  serverData;
  destination;
  constructor(forCustomer: boolean) {
    super({
      prefix: 'ci-form',
      // fields: (() => {
      //   const fieldsCopy = JSON.parse(JSON.stringify(fields));
      //   for (const field of fieldsCopy) {
      //     field.noAutoFill = forCustomer === true;
      //   }
      //   return fieldsCopy;
      // })(),
      fields: [
        { name: FN.SERVICE_TYPE, serverName: SN.SERVICE_TYPE, label: LB.SERVICE_TYPE, type: '', required: true, options: serviceTypeOptions, noDefOption: true, noAutoFill: forCustomer === true},
        { name: FN.DESTINATION, serverName: SN.DESTINATION, label: LB.DESTINATION, type: '', required: false, options: destinationOptions, noDefOption: true, noAutoFill: forCustomer === true},
        { name: FN.BL_NO, serverName: SN.BL_NO, label: LB.BL_NO, type: '', required: false, noAutoFill: forCustomer === true},
        { name: FN.DEPOSIT, serverName: SN.DEPOSIT, label: LB.DEPOSIT, type: '', required: false, noAutoFill: forCustomer === true},
        { name: FN.SERVICE_CHARGE, serverName: SN.SERVICE_CHARGE, label: LB.SERVICE_CHARGE, type: '', required: false, noAutoFill: forCustomer === true},
        { name: FN.TOTAL_WEIGHT, serverName: SN.TOTAL_WEIGHT, label: LB.TOTAL_WEIGHT, type: '', required: false, noAutoFill: forCustomer === true},
        { name: FN.LENGTH, serverName: SN.LENGTH, label: LB.LENGTH, type: '', required: false, noAutoFill: forCustomer === true},
        { name: FN.WIDTH, serverName: SN.WIDTH, label: LB.WIDTH, type: '', required: false, noAutoFill: forCustomer === true},
        { name: FN.HEIGHT, serverName: SN.HEIGHT, label: LB.HEIGHT, type: '', required: false, noAutoFill: forCustomer === true},
        { name: FN.VOLUME, serverName: SN.VOLUME, label: LB.VOLUME, type: '', required: false, readonly: true, noAutoFill: forCustomer === true},
        { name: FN.PICKUP_DATE, serverName: SN.PICKUP_DATE, label: LB.PICKUP_DATE, type: 'date', required: false, noAutoFill: forCustomer === true},
        { name: FN.PICKUP_TIME, serverName: SN.PICKUP_TIME, label: LB.PICKUP_TIME, required: false, noAutoFill: forCustomer === true},
        { name: FN.PICKUP_TYPE, serverName: SN.PICKUP_TYPE, label: LB.PICKUP_TYPE, options: pickupTypeOptions, noDefOption: true, required: false, noAutoFill: forCustomer === true},
        { name: FN.PACKAGES, serverName: SN.PACKAGES, label: LB.PACKAGES, type: '', required: false, noAutoFill: forCustomer === true},
        { name: FN.FROM_NAME_KOR, serverName: SN.FROM_NAME_KOR, label: LB.FROM_NAME_KOR, type: '', required: true, noAutoFill: forCustomer === true},
        { name: FN.FROM_NAME_ENG, serverName: SN.FROM_NAME_ENG, label: LB.FROM_NAME_ENG, type: '', required: true, placeholder: 'HONG, GIL DONG', noAutoFill: forCustomer === true},
        { name: FN.FROM_PHONE, serverName: SN.FROM_PHONE, label: LB.FROM_PHONE, type: 'text', inputMode: 'numeric', required: true, placeholder: '***-***-****', noAutoFill: forCustomer === true},
        { name: FN.FROM_PHONE2, serverName: SN.FROM_PHONE2, label: LB.FROM_PHONE2, type: 'text', inputMode: 'numeric', required: false, noAutoFill: forCustomer === true},
        { name: FN.FROM_EMAIL, serverName: SN.FROM_EMAIL, label: LB.FROM_EMAIL, type: 'email', required: true, noAutoFill: forCustomer === true},
        { name: FN.FROM_ADDR, serverName: SN.FROM_ADDR, label: LB.FROM_ADDR, type: '', required: true, noAutoFill: forCustomer === true},
        { name: FN.FROM_ADDR2, serverName: SN.FROM_ADDR2, label: LB.FROM_ADDR2, type: '', required: false, placeholder: '상세주소 별도입력', noAutoFill: forCustomer === true},
        { name: FN.FROM_CITY, serverName: SN.FROM_CITY, label: LB.FROM_CITY, type: '', required: true, noAutoFill: forCustomer === true},
        { name: FN.FROM_PROVINCE, serverName: SN.FROM_PROVINCE, label: LB.FROM_PROVINCE, type: '', required: true, options: provinceOptions, noDefOption: true, noAutoFill: forCustomer === true},
        { name: FN.FROM_COUNTRY, serverName: SN.FROM_COUNTRY, label: LB.FROM_COUNTRY, type: '', required: true, options: countryOptions, noDefOption: true, disabled: true, noAutoFill: forCustomer === true},
        { name: FN.FROM_POSTAL, serverName: SN.FROM_POSTAL, label: LB.FROM_POSTAL, type: '', required: true, noAutoFill: forCustomer === true},
        { name: FN.RETURN_DATE, serverName: SN.RETURN_DATE, label: LB.RETURN_DATE, type: 'date', required: false, noAutoFill: forCustomer === true},
        { name: FN.VISA_TYPE, serverName: SN.VISA_TYPE, label: LB.VISA_TYPE, type: '', required: false, noAutoFill: forCustomer === true},
        { name: FN.TO_NAME_KOR, serverName: SN.TO_NAME_KOR, label: LB.TO_NAME_KOR, type: '', required: true, noAutoFill: forCustomer === true},
        { name: FN.TO_NAME_ENG, serverName: SN.TO_NAME_ENG, label: LB.TO_NAME_ENG, type: '', required: true, placeholder: 'HONG, GIL DONG', noAutoFill: forCustomer === true},
        { name: FN.TO_PHONE, serverName: SN.TO_PHONE, label: LB.TO_PHONE, type: 'text', inputMode: 'numeric', required: true, placeholder: '***-***-****', noAutoFill: forCustomer === true},
        { name: FN.TO_PHONE2, serverName: SN.TO_PHONE2, label: LB.TO_PHONE2, type: 'text', inputMode: 'numeric', required: false, noAutoFill: forCustomer === true},
        { name: FN.TO_ADDR, serverName: SN.TO_ADDR, label: LB.TO_ADDR, type: '', required: true, noAutoFill: forCustomer === true},
        { name: FN.TO_ADDR2, serverName: SN.TO_ADDR2, label: LB.TO_ADDR2, type: '', required: false, placeholder: '상세주소 별도입력', noAutoFill: forCustomer === true},
        { name: FN.TO_CITY, serverName: SN.TO_CITY, label: LB.TO_CITY, type: '', required: true, noAutoFill: forCustomer === true},
        { name: FN.TO_PROVINCE, serverName: SN.TO_PROVINCE, label: LB.TO_PROVINCE, type: '', required: true, options: provinceOptions, noDefOption: true,noAutoFill: forCustomer === true},
        { name: FN.TO_COUNTRY, serverName: SN.TO_COUNTRY, label: LB.TO_COUNTRY, type: '', required: true, options: countryOptions, noDefOption: true, disabled: true, noAutoFill: forCustomer === true},
        { name: FN.TO_POSTAL, serverName: SN.TO_POSTAL, label: LB.TO_POSTAL, type: '', required: true, noAutoFill: forCustomer === true},
        { name: FN.TO_EMAIL, serverName: SN.TO_EMAIL, label: LB.TO_EMAIL, type: 'email', required: false, noAutoFill: forCustomer === true},
        { name: FN.TO_CUSTOMS_NO, serverName: SN.TO_CUSTOMS_NO, label: LB.TO_CUSTOMS_NO, type: '', required: false, noAutoFill: forCustomer === true},
        { name: FN.CONSENT, serverName: SN.CONSENT, label: LB.CONSENT, type: 'checkbox', required: false, noAutoFill: forCustomer === true},
        { name: FN.MEMO, serverName: SN.MEMO, label: LB.MEMO, type: '', required: false, noAutoFill: forCustomer === true},
        { name: FN.IS_CUSTOMER, serverName: SN.IS_CUSTOMER, label: LB.IS_CUSTOMER, type: '', required: false, noAutoFill: forCustomer === true},
        { name: FN.MAPLE_TYPE, serverName: SN.MAPLE_TYPE, label: LB.MAPLE_TYPE, type: '', required: false, options: mapleTypeOptions, noDefOption: true, noAutoFill: forCustomer === true},
        { name: FN.KIND, serverName: SN.KIND, label: '', type: '', required: false, noAutoFill: forCustomer === true},
        { name: FN.K_POST, serverName: SN.K_POST, label: '', type: '', required: false, noAutoFill: forCustomer === true},
        { name: FN.IS_DOCUMENT, serverName: SN.IS_DOCUMENT, label: '', type: 'checkbox', required: false, noAutoFill: forCustomer === true},
        // items
        { name: FN.NO, serverName: SN.NO, type: '', hide: true, noAutoFill: forCustomer === true},
        { name: FN.ITEM, serverName: SN.ITEM, label: LB.ITEM, type: '', noAutoFill: forCustomer === true},
        { name: FN.QTY, serverName: SN.QTY, label: LB.QTY, type: '', mask: util.MASK_NUMBER_ONLY, inputMode: 'numeric', noAutoFill: forCustomer === true},
        { name: FN.UNIT_PRICE, serverName: SN.UNIT_PRICE, label: LB.UNIT_PRICE, type: '', mask: util.MASK_NUMBER_DECIMAL_TWO, inputMode: 'numeric', noAutoFill: forCustomer === true},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
        horizontal: false,
        onSubmit: () => {
          const consent = this.getValue(FN.CONSENT);
          const fromEmail = this.getValue(FN.FROM_EMAIL);
          const toEmail = this.getValue(FN.TO_EMAIL);
          if(!this.grid.rows) {
            util.showWarning('Please add items.');
            return;
          }
          if (this.forCustomer === true && consent?.[0] !== 'on') {
            util.showWarning('Please check terms and conditions.');
            return;
          }
          const fromEmailValid = emailSchema.isValidSync(fromEmail);
          const toEmailValid = emailSchema.isValidSync(toEmail);
          if (!fromEmailValid) {
            util.showWarning('Please input a valid departure(shipper) email.');
            return;
          }
          if (!toEmailValid) {
            util.showWarning('Please input a valid destination(consignee) email.');
            return;
          }
          this.onSave(this.getValuesToSubmit());
        },
      },
    });
    this.forCustomer = forCustomer;
  }
  onRender = () => {
    const [totalPrice, setTotalPrice] = React.useState(0);
    const user = useUser();
    const history = useHistory();
    const modal = useConsentModal('OK', () => modal.close());
    const itemInputModal = useItemInputModal((mode, data) => {
      const values = itemInputForm.getValues(undefined, true);
      if (values) {
        const {no, item, qty, unitPrice} = values;
        const totalPrice = qty * unitPrice;
        let newRows = [];
        if(mode === 'add') {
          newRows = [...(grid.rows ?? []), {...values, totalPrice}];
          grid.setRows(newRows.map((i, index) => ({...i, no: index})));
        } else {
          grid.rows.map(row => {
            if (row.no === no) {
              row.item = item;
              row.qty = qty;
              row.unitPrice = unitPrice;
              row.totalPrice = totalPrice;
            }
          });
          newRows = [...grid.rows];
          grid.setRows([]);
          setTimeout(() => {
            grid.setRows(grid.rows);
          }, 0);
        }
        onCalcTotalPrice(newRows);
        itemInputModal.close();
      }
    });
    // set total price
    const onCalcTotalPrice = (newRows) => {
      let tPrice = 0;
      newRows.forEach(row => {
        tPrice += row.totalPrice;
      });
      setTotalPrice(tPrice);
    };
    const grid = useItemGrid(itemInputModal, onCalcTotalPrice);
    const {renderField: _r, getValue: _v, setValue: _c, getField: _f} = this;
    this.grid = grid;
    const serviceType = _v(FN.SERVICE_TYPE);
    const destination = this.destination ?? _v(FN.DESTINATION);
    const isUPS = serviceType === 'CP' || serviceType === 'UPS';
    const isAir = serviceType === 'MA';
    const isOcean = serviceType === 'MO';
    const isVendor = user.partner_type === 'VD';
    const isFromCanada = destination === 'KOREA';
    const isFromKorea = destination === 'CANADA';
    let label = '';
    if (isUPS) {
      label = '';
    } else if (this.destination === 'KOREA') {
      label = ' (캐나다 ⟶ 한국)';
    } else if (this.destination === 'CANADA') {
      label = ' (한국 ⟶ 캐나다)';
    }
    const onTerms = (e) => {
      e.preventDefault();
      modal.open(serviceType);
    };
    const onCalc = async () => {
      const grossWeight = parseFloat(_v(FN.TOTAL_WEIGHT));
      const packages = parseFloat(_v(FN.PACKAGES));
      const isDocument = _v(FN.IS_DOCUMENT)?.[0] === 'on';
      if ((grossWeight > 0 || isDocument) && packages > 0) {
        const {data: [{totalPrice = '', jShipment = {}}] = [{}]} = await api.getFreightPrice({
          portType: _v(FN.SERVICE_TYPE) === 'MO' ? 'O' : 'A',
          grossWeightKg: isDocument ? 0 : grossWeight,
          length: util.toFloat(length),
          width: util.toFloat(width),
          height: util.toFloat(height),
          packageCount: util.toInt(packages),
          isDocument,
        });
        _c(FN.SERVICE_CHARGE, totalPrice);
        _c(FN.TOTAL_WEIGHT, jShipment.grossWeightKg);
      } else {
        util.showWarning('Please input Packages and Total Weight!');
        _c(FN.IS_DOCUMENT, 'off');
      }
    };
    const onAddItems = () => {
      const no = _v(FN.NO);
      const item = _v(FN.ITEM);
      const qty = _v(FN.QTY);
      const unitPrice = _v(FN.UNIT_PRICE);
      if (!item) {
        util.showWarning('Please input an Item.');
        return null;
      }
      if (!qty) {
        util.showWarning('Please input a QTY.');
        return null;
      }
      if (!unitPrice) {
        util.showWarning('Please input a unit price.');
        return null;
      }
      const totalPrice = qty * unitPrice;
      let newRows = [];
      newRows = [...(grid.rows ?? []), {no, item, qty, unitPrice, totalPrice}];
      grid.setRows(newRows.map((i, index) => ({...i, no: index})));
      onCalcTotalPrice(newRows);
      _c(FN.NO, undefined);
      _c(FN.ITEM, undefined);
      _c(FN.QTY, undefined);
      _c(FN.UNIT_PRICE, undefined);
    };
    function handleChange(e, name) {
      _c(name, e.target.value);
    }
    const length = _v(FN.LENGTH);
    const width = _v(FN.WIDTH);
    const height = _v(FN.HEIGHT);
    const packages = _v(FN.PACKAGES);
    const pickupType = _v(FN.PICKUP_TYPE);
    const isDropOff = isVendor || pickupType !== 'REQUEST PICKUP';
    React.useEffect(() => {
      if(this.gridData) {
        onCalcTotalPrice(this.gridData);
      }
    }, [this.gridData]);
    React.useEffect(() => {
      const volumeWeight = length * width * height * packages / 5000;
      if (!isNaN(volumeWeight) && volumeWeight > 0) {
        _c(FN.VOLUME, volumeWeight.toFixed(2));
      } else {
        _c(FN.VOLUME, '');
      }
    }, [length, width, height, packages]);
    React.useEffect(() => {
      const {id: blId} = util.getQS();
      if (isDropOff) {
        const {partner_id, pickup_day, pickup_time} = user;
        const info = util.getPickupDate(partner_id, pickup_day, pickup_time);
        if (info) {
          _c(FN.PICKUP_DATE, util.formatD(info.pickupDate));
          _c(FN.PICKUP_TIME, info.pickupTime)
        }
      } else {
        if (pickupType !== undefined && blId === undefined) {
          // 수정모드가 아닌 경우에만 값을 리셋해줌
          _c(FN.PICKUP_DATE, '');
          _c(FN.PICKUP_TIME, '');
          _c(FN.LENGTH, '');
          _c(FN.WIDTH, '');
          _c(FN.HEIGHT, '');
          _c(FN.TOTAL_WEIGHT, '');
          _c(FN.VOLUME, '');
          _c(FN.SERVICE_CHARGE, '');
          _c(FN.DEPOSIT, '');
        }
      }
    }, [pickupType]);
    React.useEffect(() => {
      const {id: blId} = util.getQS();
      const packages = _v(FN.PACKAGES);
      if (blId && user.partner_type === 'DZ') {
        _f(FN.BL_NO).readonly = true;
      } else {
        _f(FN.BL_NO).readonly = false;
      }
      if (blId && user.partner_type === 'VD') {
        _c(FN.PICKUP_TYPE, 'DROP OFF');
      }
      if(!blId) {
        setTotalPrice(0);
      }
      if(!packages) {
        _c(FN.PACKAGES, 1);
      }
    }, []);
    React.useEffect(() => {
      if (this.forCustomer && isFromCanada && !isUPS) {
        _c(FN.FROM_COUNTRY, 'CANADA');
        _c(FN.TO_COUNTRY, 'KOREA');
      }
      if (this.forCustomer && isFromKorea && !isUPS) {
        _c(FN.FROM_COUNTRY, 'KOREA');
        _c(FN.TO_COUNTRY, 'CANADA');
      }
      if (isUPS) {
        _c(FN.FROM_COUNTRY, '');
        _c(FN.TO_COUNTRY, '');
        _c(FN.DESTINATION, '');
      }
    }, [isUPS, isAir, isFromCanada, isOcean, isFromKorea]);
    React.useEffect(() => {
      if (!isUPS) {
        if (destination) {
          _c(FN.FROM_COUNTRY, destination === 'KOREA' ? 'CANADA' : 'KOREA');
          _c(FN.TO_COUNTRY, destination);
        }
      }
    }, [destination]);
    const isDocument = _v(FN.IS_DOCUMENT)?.[0] === 'on';
    React.useEffect(() => {
      if (isDocument) {
        onCalc().catch();
      } else {
        _c(FN.TOTAL_WEIGHT, undefined);
        _c(FN.SERVICE_CHARGE, undefined);
      }
    }, [isDocument]);
    this.setFieldRequired();
    _f(FN.FROM_EMAIL).required = !isVendor;
    return (
      <Container className={'input-form-container px-4 pt-4'}>
        {!this.forCustomer && (
          <Card className={'pb-4'} style={{height: 'initial'}}>
            <CardBody className={'p-0'}>
              <h4 className={'mb-4'}>SERVICE TYPE</h4>
              <Row>
                <Col md={3}>{_r(FN.SERVICE_TYPE)}</Col>
                {!isUPS && <Col md={3}>{_r(FN.DESTINATION)}</Col>}
                <Col md={3}>{_r(FN.BL_NO)}</Col>
              </Row>
              <Row>
                {!isVendor && <Col md={3}>{_r(FN.PICKUP_TYPE)}</Col>}
                <Col md={3}>{_r(FN.PICKUP_DATE)}</Col>
                <Col md={3}>{_r(FN.PICKUP_TIME)}</Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {!this.forCustomer && (
          <Card className={'pb-0'} style={{height: 'initial'}}>
            <CardBody className={'p-0'}>
              <h4 className={'mb-4'}>QUOTATION</h4>
              <Row>
                <Col md={3}>{_r(FN.LENGTH)}</Col>
                <Col md={3}>{_r(FN.WIDTH)}</Col>
                <Col md={3}>{_r(FN.HEIGHT)}</Col>
                <Col md={3}>{_r(FN.PACKAGES)}</Col>
              </Row>
              <Row>
                <Col md={3}>{_r(FN.TOTAL_WEIGHT)}</Col>
                <Col md={3}>{_r(FN.VOLUME)}</Col>
                <Col md={3}>{isDropOff ? _r(FN.SERVICE_CHARGE) : _r(FN.DEPOSIT)}</Col>
                <Col md={1}>
                  <div>
                    <div style={{color: ''}}>Document</div>
                    <div style={{paddingTop: 3}}>{_r(FN.IS_DOCUMENT)}</div>
                  </div>
                </Col>
                <Col md={2} className={'flex middle'}>
                  {isDropOff && <DefaultButton label={'Calculate Price'} type={'button'} disabled={_v(FN.IS_DOCUMENT)?.[0] === 'on'} onClick={onCalc} noMargin />}
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {this.forCustomer && (
          <Row>
            <h3>{serviceTypeOptions.find(i => i.value === serviceType)?.label} {label}</h3>
          </Row>
        )}
        {isUPS && this.renderUPS()}
        {isFromCanada && isAir && this.renderAirFromCanada()}
        {isFromKorea && isAir && this.renderAirFromKorea()}
        {isFromCanada && isOcean && this.renderOceanFromCanada()}
        {isFromKorea && isOcean && this.renderOceanFromKorea()}
        <div className={'mb-4'}>
          <div className={'flex between middle mb-2'}>
            <div>
              <h4>INVOICE & PACKING LIST (영어로 작성 바랍니다)</h4>
              <div style={{color: 'red'}}>물품 총 비용이 US $150 초과이면 과세 대상자 입니다(수취인 청구). 기재하지 않은 물품에 대한 과태료 또는 세금책임은 받는 분께 있습니다.</div>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-md-9'}>
              <div className={'row'}>
                <div className={'col-md-6'}>{_r(FN.ITEM)}</div>
                <div className={'col-md-3'}>{_r(FN.QTY)}</div>
                <div className={'col-md-3'}>{_r(FN.UNIT_PRICE)}</div>
              </div>
            </div>
            <div className={'col-md-3'} style={{alignSelf: 'center'}}>
              <div className={'row'} style={{alignItems: 'flex-end'}}>
                <div className={'col-md-7'} style={{color: '#646777'}}>Total Price: {totalPrice.toFixed(2)}</div>
                <div className={'col-md-5'}><DefaultButton type={'button'} label={'ADD'} onClick={onAddItems} noMargin /></div>
              </div>
            </div>
          </div>
          {grid.render()}
        </div>
        {/*<div className={'mb-4'}>*/}
        {/*  <div className={'flex between middle mb-2'}>*/}
        {/*    <div>*/}
        {/*      <h4>INVOICE & PACKING LIST (영어로 작성 바랍니다)</h4>*/}
        {/*      <div style={{color: 'red'}}>물품 총 비용이 US $150 초과이면 과세 대상자 입니다.(수취인 청구)</div>*/}
        {/*      <div style={{color: 'red'}}>기재하지 않은 물품에 대한 과태료 또는 세금책임은 받는 분께 있습니다.</div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className={'flex between'}>*/}
        {/*    <div className={'flex flex-2'}>*/}
        {/*      <div className={'flex-2'} style={{paddingRight: '10px'}}>{_r(FN.ITEM)}</div>*/}
        {/*      <div style={{paddingRight: '10px'}}>{_r(FN.QTY)}</div>*/}
        {/*      <div style={{paddingRight: '30px'}}>{_r(FN.UNIT_PRICE)}</div>*/}
        {/*    </div>*/}
        {/*    <div className={'flex flex-1'} style={{justifyContent: 'flex-end', alignItems: 'center'}}>*/}
        {/*      <div style={{paddingRight: '30px', color: '#646777'}}>Total Price: {totalPrice.toFixed(2)}</div>*/}
        {/*      <div><DefaultButton type={'button'} label={'ADD'} onClick={onAddItems} noMargin /></div>*/}
        {/*      /!*<div ><DefaultButton type={'button'} label={'ADD'} onClick={() => itemInputModal.open('add')} noMargin /></div>*!/*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  {grid.render()}*/}
        {/*</div>*/}
        {itemInputModal.render()}
        {this.forCustomer && (
          <div className={'flex between wrap'}>
            <div className={'flex-1'}>{_r(FN.CONSENT)}</div>
            <a href={'/terms'} onClick={onTerms}>Terms and Conditions</a>
          </div>
        )}
        <div className={'flex center mb-4 pb-4'}>
          <PrimaryButton label={'SUBMIT'} type={'submit'} />
          <DefaultButton label={'GO BACK'} type={'button'} onClick={() => history.goBack()}/>
        </div>
        {modal.render()}
      </Container>
    );
  };
  onValidate = (values) => {
    // for (const field of this.fields) {
    //   if (field.required === true && values[field.name]?.length <= 0) {
    //     util.showError(`Please input ${field.label ?? field.name}`);
    //     return null;
    //   }
    // }
    return values;
  };
  setFieldRequired() {
    const setReq = (n, r) => this.getField(n).required = r;
    const serviceType = this.getValue(FN.SERVICE_TYPE);
    if (!this.forCustomer) {
      setReq(FN.FROM_CITY, false);
      setReq(FN.FROM_PROVINCE, false);
      setReq(FN.FROM_COUNTRY, false);
      setReq(FN.TO_CITY, false);
      setReq(FN.TO_PROVINCE, false);
      setReq(FN.TO_COUNTRY, false);
      this.getField(FN.FROM_COUNTRY).disabled = false;
      this.getField(FN.TO_COUNTRY).disabled = false;
      this.getField(FN.FROM_ADDR).placeholder = '';
      this.getField(FN.TO_ADDR).placeholder = '';
      if (serviceType === 'UPS' || serviceType === 'CP') {
        this.getField(FN.FROM_PROVINCE).options = undefined;
        this.getField(FN.TO_PROVINCE).options = undefined;
      } else {
        this.getField(FN.FROM_PROVINCE).options = provinceOptions;
        this.getField(FN.TO_PROVINCE).options = provinceOptions;
      }
      return;
    }
    switch (serviceType) {
      case 'MO':
      case 'MA':
        if (this.destination === 'CANADA') {
          setReq(FN.FROM_CITY, false);
          setReq(FN.FROM_PROVINCE, false);
          setReq(FN.FROM_COUNTRY, false);
          setReq(FN.TO_CITY, true);
          setReq(FN.TO_PROVINCE, true);
          setReq(FN.TO_COUNTRY, true);
          this.getField(FN.FROM_ADDR).placeholder = '주소검색 버튼으로 검색뒤 새도로명주소로 선택';
          this.getField(FN.TO_ADDR).placeholder = '';
        } else {
          setReq(FN.FROM_CITY, true);
          setReq(FN.FROM_PROVINCE, true);
          setReq(FN.FROM_COUNTRY, true);
          setReq(FN.TO_CITY, false);
          setReq(FN.TO_PROVINCE, false);
          setReq(FN.TO_COUNTRY, false);
          this.getField(FN.FROM_ADDR).placeholder = '';
          this.getField(FN.TO_ADDR).placeholder = '주소검색 버튼으로 검색뒤 새도로명주소로 선택';
        }
        this.getField(FN.FROM_COUNTRY).options = countryOptions;
        this.getField(FN.FROM_COUNTRY).disabled = true;
        this.getField(FN.TO_COUNTRY).options = countryOptions;
        this.getField(FN.TO_COUNTRY).disabled = true;
        this.getField(FN.FROM_PROVINCE).options = provinceOptions;
        this.getField(FN.TO_PROVINCE).options = provinceOptions;
        // this.getField(FN.MEMO).placeholder = serviceType === 'MO' ? '특이사항' : '배송메시지 (예: 경비실에 맡겨주세요)';
        break;
      case 'CP':
      case 'UPS':
        setReq(FN.FROM_CITY, true);
        setReq(FN.FROM_PROVINCE, true);
        setReq(FN.FROM_COUNTRY, true);
        setReq(FN.TO_CITY, true);
        setReq(FN.TO_PROVINCE, true);
        setReq(FN.TO_COUNTRY, true);
        this.getField(FN.FROM_COUNTRY).options = undefined;
        this.getField(FN.FROM_COUNTRY).disabled = false;
        this.getField(FN.TO_COUNTRY).options = undefined;
        this.getField(FN.TO_COUNTRY).disabled = false;
        this.getField(FN.FROM_PROVINCE).options = undefined;
        this.getField(FN.TO_PROVINCE).options = undefined;
        this.getField(FN.FROM_ADDR).placeholder = '';
        this.getField(FN.TO_ADDR).placeholder = '';
        break;
      default:
        break;
    }
  }
  setValuesFromServer(data) {
    const {
      jcommon: {
        blNo,
        hblNo,
        hawbNo,
        kind,
        currency,
        totalPrice,
        deposit,
        portType,
        tradeType,
        pickupType,
        pDate,
        pTime,
        isCustomer,
        mapleType,
        destination,
      },
      jcustomer: {
        shipper,
        shipperLocal,
        shipperId,
        shipperAddr,
        shipperAddr2,
        shipperCity,
        shipperProvince,
        shipperCountry,
        shipperPhone,
        shipperPhone2,
        shipperEmail,
        shipperPostalCode,
        consignee,
        consigneeLocal,
        consigneeId,
        consigneeAddr,
        consigneeAddr2,
        consigneeAddrLocal,
        consigneeCity,
        consigneeProvince,
        consigneeCountry,
        consigneePhone1,
        consigneePhone2,
        consigneePostalCode,
        consigneeEmail,
        customer,
        customerLocal,
        customerId,
        customerAddr,
        customerCity,
        customerProvince,
        customerCountry,
        customerPhone,
        customerPhone2,
        customerEmail,
        customerPostalCode,
        importNo,
        partnerId,
        kPost,
      },
      jshipment: {
        package: packageCount,
        packageType,
        grossWeightKg,
        volumeWeightKg,
        chargeableWeightKg,
        cbm,
        gridData,
        visaType,
        entDate,
        dzWidth, dzHeight, dzLength, dzServiceType,
        memo,
      },
    } = data ?? {jcommon: {}, jcustomer: {}, jshipment: {}};
    const values = {
      [FN.SERVICE_TYPE]: dzServiceType,
      [FN.BL_NO]: blNo ? blNo : (hawbNo ? hawbNo : hblNo),
      [FN.DEPOSIT]: deposit,
      [FN.SERVICE_CHARGE]: totalPrice,
      [FN.TOTAL_WEIGHT]: grossWeightKg,
      [FN.WIDTH]: dzWidth,
      [FN.LENGTH]: dzLength,
      [FN.HEIGHT]: dzHeight,
      [FN.VOLUME]: volumeWeightKg,
      [FN.PICKUP_DATE]: util.formatD(pDate),
      [FN.PICKUP_TIME]: pTime,
      [FN.PICKUP_TYPE]: pickupType,
      [FN.PACKAGES]: packageCount,
      [FN.FROM_NAME_KOR]: shipperLocal,
      [FN.FROM_NAME_ENG]: shipper,
      [FN.FROM_PHONE]: shipperPhone,
      [FN.FROM_PHONE2]: shipperPhone2,
      [FN.FROM_EMAIL]: shipperEmail,
      [FN.FROM_ADDR]: shipperAddr,
      [FN.FROM_ADDR2]: shipperAddr2,
      [FN.FROM_CITY]: shipperCity,
      [FN.FROM_PROVINCE]: shipperProvince,
      [FN.FROM_COUNTRY]: shipperCountry,
      [FN.FROM_POSTAL]: shipperPostalCode,
      [FN.RETURN_DATE]: entDate,
      [FN.VISA_TYPE]: visaType,
      [FN.TO_NAME_KOR]: consigneeLocal,
      [FN.TO_NAME_ENG]: consignee,
      [FN.TO_PHONE]: consigneePhone1,
      [FN.TO_PHONE2]: consigneePhone2,
      [FN.TO_ADDR]: consigneeAddr,
      [FN.TO_ADDR2]: consigneeAddr2,
      [FN.TO_CITY]: consigneeCity,
      [FN.TO_PROVINCE]: consigneeProvince,
      [FN.TO_COUNTRY]: consigneeCountry,
      [FN.TO_POSTAL]: consigneePostalCode,
      [FN.TO_EMAIL]: consigneeEmail,
      [FN.TO_CUSTOMS_NO]: importNo,
      [FN.MEMO]: memo,
      [FN.CONSENT]: undefined,
      [FN.IS_CUSTOMER]: isCustomer,
      [FN.MAPLE_TYPE]: mapleType,
      [FN.DESTINATION]: destination,
      [FN.KIND]: kind,
      [FN.K_POST]: kPost,
    };
    this.gridData = gridData;
    this.dropzoneId = partnerId;
    this.serverData = data;
    this.setValuesFast(values);
    this.grid.setRows(gridData);
  }
  getValuesToSubmit() {
    const {getValue: _v} = this;
    const v = this.getValues(undefined, true);
    const {width, height, length} = v;
    const volume = width * height * length / 5000;
    const serviceType = _v(FN.SERVICE_TYPE);
    const jcommon = {
      blNo: v[FN.BL_NO],
      kind: serviceType === 'CP' ? 'P' : serviceType === 'UPS' ? 'U' : 'C',
      currency: 'CAD',
      totalPrice: util.toFloat(v[FN.SERVICE_CHARGE]),
      deposit: util.toFloat(v[FN.DEPOSIT]),
      portType: v[FN.SERVICE_TYPE] === 'MO' ? 'O' : 'A', // <MAPLEBOX OCEAN>인 경우에만 'O' 로 설정함
      tradeType: 'E',
      pickupType: v[FN.PICKUP_TYPE] ?? 'DROP OFF',
      pDate: util.toTS(v[FN.PICKUP_DATE]),
      pTime: v[FN.PICKUP_TIME],
      isCustomer: v[FN.IS_CUSTOMER] ?? this.forCustomer === true, // <Customer>가 입력한 경우 TRUE
      isVendorData: true, // 벤더가 입력한 데이터인 경우 TRUE
      mapleType: v[FN.MAPLE_TYPE],
      destination: v[FN.TO_COUNTRY],
    };
    const jcustomer = {
      shipper: v[FN.FROM_NAME_ENG],
      shipperLocal: v[FN.FROM_NAME_KOR],
      // shipperId: 0,
      shipperAddr: v[FN.FROM_ADDR],
      shipperAddr2: v[FN.FROM_ADDR2],
      shipperCity: v[FN.FROM_CITY],
      shipperProvince: v[FN.FROM_PROVINCE],
      shipperCountry: v[FN.FROM_COUNTRY],
      shipperPhone: v[FN.FROM_PHONE],
      shipperPhone2: v[FN.FROM_PHONE2],
      shipperEmail: v[FN.FROM_EMAIL],
      shipperPostalCode: v[FN.FROM_POSTAL],
      consignee: v[FN.TO_NAME_ENG],
      consigneeLocal: v[FN.TO_NAME_KOR],
      // consigneeId: 0,
      consigneeAddr: v[FN.TO_ADDR],
      consigneeAddr2: v[FN.TO_ADDR2],
      consigneeAddrLocal: v[FN.TO_ADDR],
      consigneeCity: v[FN.TO_CITY],
      consigneeProvince: v[FN.TO_PROVINCE],
      consigneeCountry: v[FN.TO_COUNTRY],
      consigneePhone1: v[FN.TO_PHONE],
      consigneePhone2: v[FN.TO_PHONE2],
      consigneePostalCode: v[FN.TO_POSTAL],
      consigneeEmail: v[FN.TO_EMAIL],
      customer: v[FN.FROM_NAME_ENG],
      customerLocal: v[FN.FROM_NAME_KOR],
      // customerId: 0,
      customerAddr: v[FN.FROM_ADDR],
      customerCity: v[FN.FROM_CITY],
      customerProvince: v[FN.FROM_PROVINCE],
      customerCountry: v[FN.FROM_COUNTRY],
      customerPhone: v[FN.FROM_PHONE],
      customerPhone2: v[FN.FROM_PHONE2],
      customerEmail: v[FN.FROM_EMAIL],
      customerPostalCode: v[FN.FROM_POSTAL],
      importNo: v[FN.TO_CUSTOMS_NO],
      partnerId: this.dropzoneId,
      kPost: v[FN.K_POST] ?? [],
    };
    const jshipment = {
      package: util.toInt(v[FN.PACKAGES]),
      packageType: 'BOX',
      grossWeightKg: util.toFloat(v[FN.TOTAL_WEIGHT]),
      volumeWeightKg: isNaN(volume) ? undefined : volume,
      chargeableWeightKg: isNaN(volume) ? undefined : volume,
      cbm: undefined, // TODO
      gridData: this.grid.rows,
      visaType: v[FN.VISA_TYPE],
      entDate: v[FN.RETURN_DATE],
      dzWidth: v[FN.WIDTH],
      dzHeight: v[FN.HEIGHT],
      dzLength: v[FN.LENGTH],
      dzServiceType: v[FN.SERVICE_TYPE],
      memo: v[FN.MEMO],
    };
    if (this.serverData) {
      const {jcommon: jcommonServer, jcustomer: jcustomerServer, jshipment: jshipmentServer} = this.serverData;
      return {
        jcommon: {...jcommonServer, ...jcommon},
        jcustomer: {...jcustomerServer, ...jcustomer},
        jshipment: {...jshipmentServer, ...jshipment},
      }
    } else {
      return {
        jcommon,
        jcustomer: {...jcustomer, shipperId: 0, consigneeId: 0, customerId: 0},
        jshipment,
      };
    }
  }
  renderAirFromCanada() {
    const {renderField: _r, getValue: _v, setValue: _c, getField: _f} = this;
    const onAddressSearch = () => {
      // eslint-disable-next-line no-undef
      new daum.Postcode({
        oncomplete: function(data) {
          const {zonecode, address, buildingName} = data;
          _c(FN.TO_ADDR, address.toUpperCase() + (buildingName ? ` (${buildingName})` : ''));
          _c(FN.TO_POSTAL, zonecode.toUpperCase());
        }
      }).open();
    };
    return (
      <Row>
        <Col lg={6} className={'py-4'}>
          <Card className={'pb-0'}>
            <CardBody className={'p-0'}>
              <h4 className={'mb-4'}>DEPARTURE (출발지 정보)</h4>
              <Row>
                <Col md={6}>{_r(FN.FROM_NAME_ENG)}</Col>
                <Col md={6}>{_r(FN.FROM_NAME_KOR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_PHONE)}</Col>
                <Col md={6}>{_r(FN.FROM_PHONE2)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_ADDR)}</Col>
                <Col md={6}>{_r(FN.FROM_CITY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_PROVINCE)}</Col>
                <Col md={6}>{_r(FN.FROM_COUNTRY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_POSTAL)}</Col>
                <Col md={6}>{_r(FN.FROM_EMAIL)}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6} className={'py-4'}>
          <Card className={'pb-0'}>
            <CardBody className={'p-0'}>
              <h4 className={'mb-4'}>DESTINATION (도착지 정보)</h4>
              <Row>
                <Col md={6}>{_r(FN.TO_NAME_ENG)}</Col>
                <Col md={6}>{_r(FN.TO_NAME_KOR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_PHONE)}</Col>
                <Col md={6}>{_r(FN.TO_PHONE2)}</Col>
              </Row>
              <Row>
                <Col md={9}>{_r(FN.TO_ADDR)}</Col>
                <Col md={3} className={'flex middle'}>
                  <DefaultButton label={'주소검색'} type={'button'} onClick={onAddressSearch} noMargin style={{whiteSpace: 'nowrap'}} />
                </Col>
              </Row>
              {this.forCustomer && (
                <Row>
                  <Col md={9}>{_r(FN.TO_ADDR2)}</Col>
                </Row>
              )}
              <Row>
                <Col md={6}>{_r(FN.TO_POSTAL)}</Col>
                <Col md={6}>{_r(FN.TO_COUNTRY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_CUSTOMS_NO)}</Col>
              </Row>
              <Row>
                <Col>{_r(FN.MEMO)}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
  renderAirFromKorea() {
    const {renderField: _r, getValue: _v, setValue: _c, getField: _f} = this;
    const onAddressSearch = () => {
      // eslint-disable-next-line no-undef
      new daum.Postcode({
        oncomplete: function(data) {
          const {zonecode, address, buildingName} = data;
          _c(FN.FROM_ADDR, address.toUpperCase() + (buildingName ? ` (${buildingName})` : ''));
          _c(FN.FROM_POSTAL, zonecode.toUpperCase());
        }
      }).open();
    };
    return (
      <Row>
        <Col lg={6} className={'py-4'}>
          <Card className={'pb-0'}>
            <CardBody className={'p-0'}>
              <h4 className={'mb-4'}>DEPARTURE (출발지 정보)</h4>
              <Row>
                <Col md={6}>{_r(FN.FROM_NAME_ENG)}</Col>
                <Col md={6}>{_r(FN.FROM_NAME_KOR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_PHONE)}</Col>
                <Col md={6}>{_r(FN.FROM_PHONE2)}</Col>
              </Row>
              <Row>
                <Col md={9}>{_r(FN.FROM_ADDR)}</Col>
                <Col md={3} className={'flex middle'}>
                  <DefaultButton label={'주소검색'} type={'button'} onClick={onAddressSearch} noMargin style={{whiteSpace: 'nowrap'}} />
                </Col>
              </Row>
              {this.forCustomer && (
                <Row>
                  <Col md={9}>{_r(FN.FROM_ADDR2)}</Col>
                </Row>
              )}
              <Row>
                <Col md={6}>{_r(FN.FROM_POSTAL)}</Col>
                <Col md={6}>{_r(FN.FROM_COUNTRY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_EMAIL)}</Col>
                <Col md={6}>{''}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6} className={'py-4'}>
          <Card className={'pb-0'}>
            <CardBody className={'p-0'}>
              <h4 className={'mb-4'}>DESTINATION (도착지 정보)</h4>
              <Row>
                <Col md={6}>{_r(FN.TO_NAME_ENG)}</Col>
                <Col md={6}>{_r(FN.TO_NAME_KOR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_PHONE)}</Col>
                <Col md={6}>{_r(FN.TO_PHONE2)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_ADDR)}</Col>
                <Col md={6}>{_r(FN.TO_CITY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_PROVINCE)}</Col>
                <Col md={6}>{_r(FN.TO_COUNTRY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_POSTAL)}</Col>
                {/*<Col md={6}>{_r(FN.TO_CUSTOMS_NO)}</Col>*/}
              </Row>
              {/*<Row>*/}
              {/*  <Col>{_r(FN.MEMO)}</Col>*/}
              {/*</Row>*/}
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
  renderOceanFromCanada() {
    const {renderField: _r, getValue: _v, setValue: _c, getField: _f} = this;
    const onAddressSearch = () => {
      // eslint-disable-next-line no-undef
      new daum.Postcode({
        oncomplete: function(data) {
          const {zonecode, address, buildingName} = data;
          _c(FN.TO_ADDR, address.toUpperCase() + (buildingName ? ` (${buildingName})` : ''));
          _c(FN.TO_POSTAL, zonecode.toUpperCase());
        }
      }).open();
    };
    return (
      <Row>
        <Col lg={6} className={'py-4'}>
          <Card className={'pb-0'}>
            <CardBody className={'p-0'}>
              <h4 className={'mb-4'}>DEPARTURE (출발지 정보)</h4>
              <Row>
                <Col md={6}>{_r(FN.FROM_NAME_ENG)}</Col>
                <Col md={6}>{_r(FN.FROM_NAME_KOR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_PHONE)}</Col>
                <Col md={6}>{_r(FN.FROM_PHONE2)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_ADDR)}</Col>
                <Col md={6}>{_r(FN.FROM_CITY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_PROVINCE)}</Col>
                <Col md={6}>{_r(FN.FROM_COUNTRY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_POSTAL)}</Col>
                <Col md={6}>{_r(FN.FROM_EMAIL)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.MAPLE_TYPE)}</Col>
                <Col md={6} />
              </Row>
              <Row>
                <Col md={6}>{_r(FN.RETURN_DATE)}</Col>
                <Col md={6}>{_r(FN.VISA_TYPE)}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6} className={'py-4'}>
          <Card className={'pb-0'}>
            <CardBody className={'p-0'}>
              <h4 className={'mb-4'}>DESTINATION (도착지 정보)</h4>
              <Row>
                <Col md={6}>{_r(FN.TO_NAME_ENG)}</Col>
                <Col md={6}>{_r(FN.TO_NAME_KOR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_PHONE)}</Col>
                <Col md={6}>{_r(FN.TO_PHONE2)}</Col>
              </Row>
              <Row>
                <Col md={9}>{_r(FN.TO_ADDR)}</Col>
                <Col md={3} className={'flex middle'}>
                  <DefaultButton label={'주소검색'} type={'button'} onClick={onAddressSearch} noMargin style={{whiteSpace: 'nowrap'}} />
                </Col>
              </Row>
              {this.forCustomer && (
                <Row>
                  <Col md={9}>{_r(FN.TO_ADDR2)}</Col>
                </Row>
              )}
              <Row>
                <Col md={6}>{_r(FN.TO_POSTAL)}</Col>
                <Col md={6}>{_r(FN.TO_COUNTRY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{''}</Col>
              </Row>
              <Row>
                <Col>{_r(FN.MEMO)}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
  renderOceanFromKorea() {
    const {renderField: _r, getValue: _v, setValue: _c, getField: _f} = this;
    const onAddressSearch = () => {
      // eslint-disable-next-line no-undef
      new daum.Postcode({
        oncomplete: function(data) {
          const {zonecode, address, buildingName} = data;
          _c(FN.FROM_ADDR, address.toUpperCase() + (buildingName ? ` (${buildingName})` : ''));
          _c(FN.FROM_POSTAL, zonecode.toUpperCase());
        }
      }).open();
    };
    return (
      <Row>
        <Col lg={6} className={'py-4'}>
          <Card className={'pb-0'}>
            <CardBody className={'p-0'}>
              <h4 className={'mb-4'}>DEPARTURE (출발지 정보)</h4>
              <Row>
                <Col md={6}>{_r(FN.FROM_NAME_ENG)}</Col>
                <Col md={6}>{_r(FN.FROM_NAME_KOR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_PHONE)}</Col>
                <Col md={6}>{_r(FN.FROM_PHONE2)}</Col>
              </Row>
              <Row>
                <Col md={9}>{_r(FN.FROM_ADDR)}</Col>
                <Col md={3} className={'flex middle'}>
                  <DefaultButton label={'주소검색'} type={'button'} onClick={onAddressSearch} noMargin style={{whiteSpace: 'nowrap'}} />
                </Col>
              </Row>
              {this.forCustomer && (
                <Row>
                  <Col md={9}>{_r(FN.FROM_ADDR2)}</Col>
                </Row>
              )}
              <Row>
                <Col md={6}>{_r(FN.FROM_POSTAL)}</Col>
                <Col md={6}>{_r(FN.FROM_COUNTRY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.MAPLE_TYPE)}</Col>
                <Col md={6}>{_r(FN.FROM_EMAIL)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.RETURN_DATE)}</Col>
                <Col md={6}>{_r(FN.VISA_TYPE)}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6} className={'py-4'}>
          <Card className={'pb-0'}>
            <CardBody className={'p-0'}>
              <h4 className={'mb-4'}>DESTINATION (도착지 정보)</h4>
              <Row>
                <Col md={6}>{_r(FN.TO_NAME_ENG)}</Col>
                <Col md={6}>{_r(FN.TO_NAME_KOR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_PHONE)}</Col>
                <Col md={6}>{_r(FN.TO_PHONE2)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_ADDR)}</Col>
                <Col md={6}>{_r(FN.TO_CITY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_PROVINCE)}</Col>
                <Col md={6}>{_r(FN.TO_COUNTRY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_POSTAL)}</Col>
                <Col md={6}>{''}</Col>
              </Row>
              <Row>
                <Col>{_r(FN.MEMO)}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
  renderUPS() {
    const {renderField: _r, getValue: _v, setValue: _c, getField: _f} = this;
    return (
      <Row>
        <Col lg={6} className={'py-4'}>
          <Card className={'pb-0'}>
            <CardBody className={'p-0'}>
              <h4 className={'mb-4'}>DEPARTURE (출발지 정보)</h4>
              <Row>
                <Col md={6}>{_r(FN.FROM_NAME_ENG)}</Col>
                <Col md={6}>{_r(FN.FROM_NAME_KOR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_PHONE)}</Col>
                <Col md={6}>{_r(FN.FROM_PHONE2)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_ADDR)}</Col>
                <Col md={6}>{_r(FN.FROM_CITY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_PROVINCE)}</Col>
                <Col md={6}>{_r(FN.FROM_COUNTRY)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.FROM_POSTAL)}</Col>
                <Col md={6}>{_r(FN.FROM_EMAIL)}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6} className={'py-4'}>
          <Card className={'pb-0'}>
            <CardBody className={'p-0'}>
              <h4 className={'mb-4'}>DESTINATION (도착지 정보)</h4>
              <Row>
                <Col md={6}>{_r(FN.TO_NAME_ENG)}</Col>
                <Col md={6}>{_r(FN.TO_NAME_KOR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_PHONE)}</Col>
                <Col md={6}>{_r(FN.TO_PHONE2)}</Col>
              </Row>
              <Row>
                <Col md={12}>{_r(FN.TO_ADDR)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_CITY)}</Col>
                <Col md={6}>{_r(FN.TO_PROVINCE)}</Col>
              </Row>
              <Row>
                <Col md={6}>{_r(FN.TO_COUNTRY)}</Col>
                <Col md={6}>{_r(FN.TO_POSTAL)}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export const serviceTypeOptions = [
  {value: '', label: ''},
  {value: 'MA', label: '항공 택배 (AIR)\n'},
  {value: 'MO', label: '선박 MAPLEBOX\n'},
  {value: 'CP', label: 'CANADA POST\n(소형 1박스일 경우)'},
  {value: 'UPS', label: 'UPS\n(대형 1박스 이상일 경우)'},
];

export const pickupTypeOptions = [
  {value: 'DROP OFF', label: 'DROP OFF'},
  {value: 'REQUEST PICKUP', label: 'REQUEST PICKUP'},
];

export const mapleTypeOptions = [
  {value: 'Maple Box', label: 'Maple Box (20KG)'},
  {value: 'Maple Bag', label: 'Maple Bag (35KG)'},
  {value: 'Maple Bundle', label: 'Maple Bundle'},
];

// export const countryOptions = [
//   {value: 'CANADA', label: 'CANADA'},
//   {value: 'CANADA', label: 'CANADA'},
// ];

export const destinationOptions = [
  {value: '', label: ''},
  {value: 'KOREA', label: '캐나다 ⟶ 한국'},
  {value: 'CANADA', label: '한국 ⟶ 캐나다'},
];

const countryOptions = undefined;

export const provinceOptions = [
  {value: '', label: ''},
  {label: 'Alberta', value: 'AB'},
  {label: 'British Columbia', value: 'BC'},
  {label: 'Manitoba', value: 'MB'},
  {label: 'New Brunswick', value: 'NB'},
  {label: 'Newfoundland and Labrador', value: 'NL'},
  {label: 'Northwest Territories', value: 'NT'},
  {label: 'Nova Scotia', value: 'NS'},
  {label: 'Nunavut', value: 'NU'},
  {label: 'Ontario', value: 'ON'},
  {label: 'Prince Edward Island', value: 'PE'},
  {label: 'Quebec', value: 'QC'},
  {label: 'Saskatchewan', value: 'SK'},
  {label: 'Yukon', value: 'YT'},
];

const FN = {
  SERVICE_TYPE: 'serviceType',
  BL_NO: 'blNo',
  DEPOSIT: 'deposit',
  SERVICE_CHARGE: 'serviceCharge',
  TOTAL_WEIGHT: 'totalWeight',
  LENGTH: 'length',
  WIDTH: 'width',
  HEIGHT: 'height',
  VOLUME: 'volume',
  PICKUP_DATE: 'pickupDate',
  PICKUP_TIME: 'pickupTime',
  PICKUP_TYPE: 'pickupType',
  PACKAGES: 'packages',
  FROM_NAME_KOR: 'fromNameKor',
  FROM_NAME_ENG: 'fromNameEng',
  FROM_PHONE: 'fromPhone',
  FROM_PHONE2: 'fromPhone2',
  FROM_EMAIL: 'fromEmail',
  FROM_ADDR: 'fromAddr',
  FROM_ADDR2: 'fromAddr2',
  FROM_CITY: 'fromCity',
  FROM_PROVINCE: 'fromProvince',
  FROM_COUNTRY: 'fromCountry',
  FROM_POSTAL: 'fromPostal',
  RETURN_DATE: 'returnDate',
  VISA_TYPE: 'visaType',
  TO_NAME_KOR: 'toNameKor',
  TO_NAME_ENG: 'toNameEng',
  TO_PHONE: 'toPhone',
  TO_PHONE2: 'toPhone2',
  TO_ADDR: 'toAddr',
  TO_ADDR2: 'toAddr2',
  TO_CITY: 'toCity',
  TO_PROVINCE: 'toProvince',
  TO_COUNTRY: 'toCountry',
  TO_POSTAL: 'toPostal',
  TO_EMAIL: 'toEmail',
  TO_CUSTOMS_NO: 'toCustomsNo',
  CONSENT: 'consent',
  MEMO: 'memo',
  IS_CUSTOMER: 'isCustomer',
  MAPLE_TYPE: 'mapleType',
  DESTINATION: 'destination',
  KIND: 'kind',
  K_POST: 'kPost',
  IS_DOCUMENT: 'isDocument',
  // items
  NO: 'no',
  ITEM: 'item',
  QTY: 'qty',
  UNIT_PRICE: 'unitPrice',
};

const LB = {
  SERVICE_TYPE: 'Service Type',
  BL_NO: 'B/L No.',
  DEPOSIT: 'Deposit (CAD)',
  SERVICE_CHARGE: 'Total Price (CAD)',
  TOTAL_WEIGHT: 'Total Weight (KG)',
  LENGTH: 'Length (cm)',
  WIDTH: 'Width (cm)',
  HEIGHT: 'Height (cm)',
  VOLUME: 'Volume Weight (KG)',
  PICKUP_DATE: 'Pickup Date',
  PICKUP_TIME: 'Pickup Time',
  PICKUP_TYPE: 'Pickup Type',
  PACKAGES: 'Packages',
  FROM_NAME_KOR: 'Name (한글성함)',
  FROM_NAME_ENG: 'Name (영문성함)',
  FROM_PHONE: 'Phone (연락처)',
  FROM_PHONE2: 'Phone2 (연락처2)',
  FROM_EMAIL: 'Email (이메일)',
  FROM_ADDR: 'Address (주소)',
  FROM_CITY: 'City (도시)',
  FROM_PROVINCE: 'Province (주)',
  FROM_COUNTRY: 'Country (국가)',
  FROM_POSTAL: 'Postal Code (우편번호)',
  RETURN_DATE: 'Return Date (귀국날짜 )',
  VISA_TYPE: 'Visa Type & Visit Duration (비자종류)',
  TO_NAME_KOR: 'Name (한글성함)',
  TO_NAME_ENG: 'Name (영문성함)',
  TO_PHONE: 'Phone (연락처)',
  TO_PHONE2: 'Phone2 (연락처2)',
  TO_EMAIL: 'Email (이메일)',
  TO_ADDR: 'Address (주소)',
  TO_CITY: 'City (도시)',
  TO_PROVINCE: 'Province (주)',
  TO_COUNTRY: 'Country (국가)',
  TO_POSTAL: 'Postal Code (우편번호)',
  TO_CUSTOMS_NO: 'ID/Customs No. (수취인 주민번호 또는 통관부호)',
  CONSENT: 'I agree to the Coship Terms and Conditions',
  MEMO: 'Memo (배송메시지)',
  IS_CUSTOMER: 'Is Customer',
  MAPLE_TYPE: 'Maple Type (메이플타입)',
  DESTINATION: 'Destination',
  KIND: 'kind',
  K_POST: 'kPost',
  IS_DOCUMENT: 'isDocument',
  // items
  NO: 'No',
  ITEM: 'Item (물품)',
  QTY: 'QTY (갯수)',
  UNIT_PRICE: 'Unit Price (개당 가격)',
};

const SN = FN;

// const fields = [
//   { name: FN.SERVICE_TYPE, serverName: SN.SERVICE_TYPE, label: LB.SERVICE_TYPE, type: '', required: true, options: serviceTypeOptions, noDefOption: true, },
//   { name: FN.DESTINATION, serverName: SN.DESTINATION, label: LB.DESTINATION, type: '', required: false, options: destinationOptions, noDefOption: true, },
//   { name: FN.BL_NO, serverName: SN.BL_NO, label: LB.BL_NO, type: '', required: false, },
//   { name: FN.DEPOSIT, serverName: SN.DEPOSIT, label: LB.DEPOSIT, type: '', required: false, },
//   { name: FN.SERVICE_CHARGE, serverName: SN.SERVICE_CHARGE, label: LB.SERVICE_CHARGE, type: '', required: false },
//   { name: FN.TOTAL_WEIGHT, serverName: SN.TOTAL_WEIGHT, label: LB.TOTAL_WEIGHT, type: '', required: false, },
//   { name: FN.LENGTH, serverName: SN.LENGTH, label: LB.LENGTH, type: '', required: false, },
//   { name: FN.WIDTH, serverName: SN.WIDTH, label: LB.WIDTH, type: '', required: false, },
//   { name: FN.HEIGHT, serverName: SN.HEIGHT, label: LB.HEIGHT, type: '', required: false, },
//   { name: FN.VOLUME, serverName: SN.VOLUME, label: LB.VOLUME, type: '', required: false, readonly: true},
//   { name: FN.PICKUP_DATE, serverName: SN.PICKUP_DATE, label: LB.PICKUP_DATE, type: 'date', required: false, },
//   { name: FN.PICKUP_TIME, serverName: SN.PICKUP_TIME, label: LB.PICKUP_TIME, required: false, },
//   { name: FN.PICKUP_TYPE, serverName: SN.PICKUP_TYPE, label: LB.PICKUP_TYPE, options: pickupTypeOptions, noDefOption: true, required: false, },
//   { name: FN.PACKAGES, serverName: SN.PACKAGES, label: LB.PACKAGES, type: '', required: false, },
//   { name: FN.FROM_NAME_KOR, serverName: SN.FROM_NAME_KOR, label: LB.FROM_NAME_KOR, type: '', required: true, },
//   { name: FN.FROM_NAME_ENG, serverName: SN.FROM_NAME_ENG, label: LB.FROM_NAME_ENG, type: '', required: true, placeholder: 'HONG, GIL DONG'},
//   { name: FN.FROM_PHONE, serverName: SN.FROM_PHONE, label: LB.FROM_PHONE, type: 'text', inputMode: 'numeric', required: true, placeholder: '***-***-****'},
//   { name: FN.FROM_PHONE2, serverName: SN.FROM_PHONE2, label: LB.FROM_PHONE2, type: 'text', inputMode: 'numeric', required: false, },
//   { name: FN.FROM_EMAIL, serverName: SN.FROM_EMAIL, label: LB.FROM_EMAIL, type: 'email', required: true, },
//   { name: FN.FROM_ADDR, serverName: SN.FROM_ADDR, label: LB.FROM_ADDR, type: '', required: true, },
//   { name: FN.FROM_ADDR2, serverName: SN.FROM_ADDR2, label: LB.FROM_ADDR2, type: '', required: false, placeholder: '상세주소 별도입력' },
//   { name: FN.FROM_CITY, serverName: SN.FROM_CITY, label: LB.FROM_CITY, type: '', required: true, },
//   { name: FN.FROM_PROVINCE, serverName: SN.FROM_PROVINCE, label: LB.FROM_PROVINCE, type: '', required: true, options: provinceOptions, noDefOption: true, },
//   { name: FN.FROM_COUNTRY, serverName: SN.FROM_COUNTRY, label: LB.FROM_COUNTRY, type: '', required: true, options: countryOptions, noDefOption: true, disabled: true },
//   { name: FN.FROM_POSTAL, serverName: SN.FROM_POSTAL, label: LB.FROM_POSTAL, type: '', required: true, },
//   { name: FN.RETURN_DATE, serverName: SN.RETURN_DATE, label: LB.RETURN_DATE, type: 'date', required: false, },
//   { name: FN.VISA_TYPE, serverName: SN.VISA_TYPE, label: LB.VISA_TYPE, type: '', required: false, placeholder: '비자종류 및 비자만료날짜' },
//   { name: FN.TO_NAME_KOR, serverName: SN.TO_NAME_KOR, label: LB.TO_NAME_KOR, type: '', required: true, },
//   { name: FN.TO_NAME_ENG, serverName: SN.TO_NAME_ENG, label: LB.TO_NAME_ENG, type: '', required: true, placeholder: 'HONG, GIL DONG'},
//   { name: FN.TO_PHONE, serverName: SN.TO_PHONE, label: LB.TO_PHONE, type: 'text', inputMode: 'numeric', required: true, placeholder: '***-***-****', },
//   { name: FN.TO_PHONE2, serverName: SN.TO_PHONE2, label: LB.TO_PHONE2, type: 'text', inputMode: 'numeric', required: false, },
//   { name: FN.TO_ADDR, serverName: SN.TO_ADDR, label: LB.TO_ADDR, type: '', required: true, },
//   { name: FN.TO_ADDR2, serverName: SN.TO_ADDR2, label: LB.TO_ADDR2, type: '', required: false, placeholder: '상세주소 별도입력'},
//   { name: FN.TO_CITY, serverName: SN.TO_CITY, label: LB.TO_CITY, type: '', required: true, },
//   { name: FN.TO_PROVINCE, serverName: SN.TO_PROVINCE, label: LB.TO_PROVINCE, type: '', required: true, options: provinceOptions, noDefOption: true,},
//   { name: FN.TO_COUNTRY, serverName: SN.TO_COUNTRY, label: LB.TO_COUNTRY, type: '', required: true, options: countryOptions, noDefOption: true, disabled: true },
//   { name: FN.TO_POSTAL, serverName: SN.TO_POSTAL, label: LB.TO_POSTAL, type: '', required: true, },
//   { name: FN.TO_EMAIL, serverName: SN.TO_EMAIL, label: LB.TO_EMAIL, type: 'email', required: false, },
//   { name: FN.TO_CUSTOMS_NO, serverName: SN.TO_CUSTOMS_NO, label: LB.TO_CUSTOMS_NO, type: '', required: false, placeholder: '수취인 주민번호 또는 통관부호'},
//   { name: FN.CONSENT, serverName: SN.CONSENT, label: LB.CONSENT, type: 'checkbox', required: false, },
//   { name: FN.MEMO, serverName: SN.MEMO, label: LB.MEMO, type: '', required: false, placeholder: '배송메시지 (예: 경비실에 맡겨주세요)'},
//   { name: FN.IS_CUSTOMER, serverName: SN.IS_CUSTOMER, label: LB.IS_CUSTOMER, type: '', required: false, },
//   { name: FN.MAPLE_TYPE, serverName: SN.MAPLE_TYPE, label: LB.MAPLE_TYPE, type: '', required: false, options: mapleTypeOptions, noDefOption: true},
//   { name: FN.KIND, serverName: SN.KIND, label: '', type: '', required: false, },
//   { name: FN.K_POST, serverName: SN.K_POST, label: '', type: '', required: false, },
//   { name: FN.IS_DOCUMENT, serverName: SN.IS_DOCUMENT, label: '', type: 'checkbox', required: false, },
//   // items
//   { name: FN.NO, serverName: SN.NO, type: '', hide: true},
//   { name: FN.ITEM, serverName: SN.ITEM, label: LB.ITEM, type: '', noAutoFill: true},
//   { name: FN.QTY, serverName: SN.QTY, label: LB.QTY, type: '', mask: util.MASK_NUMBER_ONLY, noAutoFill: true, inputMode: 'numeric'},
//   { name: FN.UNIT_PRICE, serverName: SN.UNIT_PRICE, label: LB.UNIT_PRICE, type: '', mask: util.MASK_NUMBER_DECIMAL_TWO, noAutoFill: true, inputMode: 'numeric'},
// ];

function useItemGrid(itemInputModal, onCalcTotalPrice) {
  const gridRef = React.useRef();
  const grid = useSimpleGrid({
    height: 300,
    columns: [
      {field: 'no', headerName: 'No.', width: 45, valueFormatter: (p) => p.value + 1},
      {field: 'item', headerName: 'Item', flex: 1, minWidth: 100},
      {field: 'qty', headerName: 'QTY', width: 50},
      {field: 'unitPrice', headerName: 'Unit Price', width: 85, valueFormatter: util.currencyFormatter},
      {field: 'totalPrice', headerName: 'Total Price', width: 90, valueFormatter: util.currencyFormatter},
    ],
    actions: ['delete'],
    actionWidth: 85,
    actionType: 'itemGrid',
    onAction: (action, data) => {
      if (action === 'edit') {
        itemInputModal.open('edit', data);
      } else if (action === 'delete') {
        // util.showConfirm('Are you sure to delete?', async () => {
        //   const curGrid = gridRef.current;
        //   const newRows = curGrid.rows
        //     .filter(i => i['no'] !== data['no'])
        //     .map((i, index) => ({...i, no: index}));
        //   curGrid.setRows(newRows);
        //   onCalcTotalPrice(newRows);
        // });
        const curGrid = gridRef.current;
        const newRows = curGrid.rows.filter(i => i['no'] !== data['no']).map((i, index) => ({...i, no: index}));
        curGrid.setRows(newRows);
        onCalcTotalPrice(newRows);
      }
    },
  });
  gridRef.current = grid;
  return grid;
}

const itemInputForm = new ItemInputForm();

function useItemInputModal(onSave) {
  return useSimpleEditModal({
    form: itemInputForm,
    onSave,
    title: 'ITEM',
    width: 360,
  });
}

export default CustomerInputForm;
